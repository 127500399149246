import { useContext } from 'react';
import CloseTwoToneIcon from '@mui/icons-material/CloseTwoTone';
import MenuTwoToneIcon from '@mui/icons-material/MenuTwoTone';
import { IconButton } from '@mui/material';
import { SidebarContext } from 'src/contexts/SidebarContext';

import HeaderUserBox from './Userbox';

const Header = () => {
  const { sidebarToggle, toggleSidebar } = useContext(SidebarContext);

  return (
    <div className="flex items-center w-full z-6 h-[80px] text-primary justify-between bg-white px-4">
      <div className="flex items-center">
        <HeaderUserBox />
        <div className="sm:hidden inline-block">
          <IconButton color="primary" onClick={toggleSidebar}>
            {!sidebarToggle ? <MenuTwoToneIcon /> : <CloseTwoToneIcon />}
          </IconButton>
        </div>
      </div>
    </div>
  );
};

export default Header;
