import { useContext } from 'react';
import { Drawer, useTheme } from '@mui/material';
import Logo from 'src/components/Logo';
import { SidebarContext } from 'src/contexts/SidebarContext';

import SidebarMenu from './SidebarMenu';
import SidebarTopSection from './SidebarTopSection';

const Sidebar = () => {
  const { sidebarToggle, toggleSidebar } = useContext(SidebarContext);
  const theme = useTheme();

  return (
    <>
      <div className="hidden sm:inline-block fixed left-0 top-0 w-[290px] bg-white text-primary shadow-[2px_0px_3px_1px_#00000004] z-7 h-full">
        <div className="overflow-y-scroll h-full">
          <div className="p-3">
            <div className="mt-2 mb-3">
              <Logo />
            </div>
            <SidebarTopSection />
          </div>
          <SidebarMenu />
        </div>
      </div>
      <Drawer
        className="shadow-[2px_0px_3px_1px_#00000004]"
        anchor={theme.direction === 'rtl' ? 'right' : 'left'}
        open={sidebarToggle}
        onClose={toggleSidebar}
        variant="temporary"
        elevation={9}
      >
        <div className="w-[290px] bg-white text-primary shadow-[2px_0px_3px_1px_#00000004] z-7 h-full">
          <div className="overflow-y-scroll h-full">
            <div className="p-3">
              <div className="mt-1 mb-3 ml-1">
                <Logo />
              </div>
              <SidebarTopSection />
            </div>
            <SidebarMenu />
          </div>
        </div>
      </Drawer>
    </>
  );
};

export default Sidebar;
